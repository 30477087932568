@use "../abstracts" as *;

.contact {
  @include containMod;

  &-map {
    iframe {
      width: 100%;
      height: 500px;
      @include mq(medium-sm) {
        height: 350px;
      }
      @include mq(small-xs) {
        height: 250px;
      }
    }
  }

  &-area {
    display: flex;
    justify-content: space-between;
    @include mq(medium) {
      flex-direction: column;
    }
    gap: 4em;
    min-height: auto !important;

    &-left {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &-right {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 1em;
      justify-content: center;
      align-items: center;
      position: relative;
      &::before {
        position: absolute;
        content: "";
        bottom: 75%;
        left: 50%;
        transform: translateX(-50%);
        height: 40%;
        width: 1px;
        background: linear-gradient(
          to bottom,
          map-get($colors, gradientdark),
          map-get($colors, gradientblue)
        );
        border-radius: 100%;
        @include mq(medium) {
          background: transparent;
        }
      }

      &::after {
        position: absolute;
        content: "";
        top: 75%;
        left: 50%;
        transform: translateX(-50%);
        height: 40%;
        width: 1px;
        background: linear-gradient(
          to bottom,
          map-get($colors, gradientblue),
          map-get($colors, gradientdark)
        );
        border-radius: 100%;
        @include mq(medium) {
          background: transparent;
        }
      }

      .contact-btn {
        width: 450px;
        @include mq(small) {
          width: 100%;
        }
        border-radius: 8px;
        text-align: center;
        padding: 1.6em;
        font-size: 1rem;
        background: linear-gradient(
          160deg,
          rgb(75, 122, 135),
          rgb(94, 168, 179)
        );
      }
    }
  }
}
