@use "../abstracts" as *;

.project {
  @include containMod;

  &-area {
    padding-top: 0 !important;
    outline: none;

    &-grid {
      display: grid;
      height: 100%;
      grid-template-rows: fit-content(100%);
      grid-template-columns: repeat(5, 1fr);
      @include mq(medium) {
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        gap: 30px;
      }
      gap: 20px;

      .card-four {
        border-radius: 8px;
        overflow: hidden;
        padding: 1.2em;
        position: relative;
        background-color: #0505065d;
        background-repeat: no-repeat;
        background-blend-mode: multiply;
        background-position: bottom left;
        background-size: cover;

        &::before {
          position: absolute;
          content: "";
          inset: 0;
          background-color: transparent;
          transition: all 0.5s ease-in-out;
        }

        h2 {
          font-size: clamp(0.9rem, 3vw, 1.1rem);
          font-weight: 500;
          margin-bottom: 0.9em;
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          transition: all 0.5s ease-in-out;
        }

        p {
          font-size: clamp(0.7rem, 3vw, 0.85rem);
          position: relative;
          top: 100%;
          opacity: 0;
          transition: all 0.5s ease-in-out;
        }

        &:hover::before {
          background-color: #191b21e7;
        }

        &:hover h2 {
          top: 0;
          transform: none;
        }

        &:hover p {
          top: 0;
          opacity: 1;
        }
      }

      & .one {
        grid-row: span 1;
        grid-column: span 2;
        @include mq(medium) {
          grid-row: span 1;
          grid-column: span 1;
        }
      }

      & .two {
        grid-row: span 2;
        grid-column: span 3;
        @include mq(medium) {
          grid-row: span 1;
          grid-column: span 1;
        }
      }

      & .three {
        grid-row: span 2;
        grid-column: span 2;
        @include mq(medium) {
          grid-row: span 1;
          grid-column: span 1;
        }
      }

      & .pivot {
        grid-row: span 1;
        grid-column: span 1;
        background-color: map-get($colors, dark) !important;
        @include mq(medium) {
          display: none;
        }
      }

      & .four {
        grid-row: span 3;
        grid-column: span 2;
        @include mq(medium) {
          grid-row: span 1;
          grid-column: span 1;
        }
      }

      & .five {
        grid-row: span 2;
        grid-column: span 3;
        @include mq(medium) {
          grid-row: span 1;
          grid-column: span 1;
        }
      }

      & .six {
        grid-row: span 2;
        grid-column: span 2;
        @include mq(medium) {
          grid-row: span 1;
          grid-column: span 1;
        }
      }
      & .seven {
        grid-row: span 2;
        grid-column: span 3;
        @include mq(medium) {
          grid-row: span 1;
          grid-column: span 1;
        }
      }
      & .eight {
        grid-row: span 1;
        grid-column: span 5;
        @include mq(medium) {
          grid-row: span 1;
          grid-column: span 1;
        }
      }
    }
  }
}
