@use "../abstracts" as *;
@use "../components" as *;

body {
  font-family: "Poppins", sans-serif;
  color: map-get($colors, textcol);
  background-image: url(/assets/img/back.png);
  background-position: left center;
  background-color: map-get($colors, background);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

p {
  font-size: map-get($fz, text);
  font-weight: 300;
  line-height: 1.6;
}

.wrapper {
  width: 100%;
  min-height: 100vh;
  position: relative;
}

// <<<<<<<<<< CONTENT AREA >>>>>>>>>>>>>>>
.content-area {
  width: 100%;
  min-height: 100vh;
  padding-top: 8em;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.hide {
    opacity: 0;
    visibility: hidden;
  }
}
