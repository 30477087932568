// Burger Dimensions
$button-width: 27px;
$bar-width: $button-width * 0.7;
$bar-height: $button-width * 0.08;

// Colors
$colors: (
  textcol: rgba(220, 220, 220, 0.927),
  background: #1d2026,
  dark: #242830,
  gradientdark: rgb(43, 34, 65),
  gradientdark1: #15171c,
  gradientblue: rgb(66, 161, 211),
  gradienttransdark: #24272d58,
);

// FontSize
$fz: (
  header: clamp(1.2rem, 9.5vw, 2.8rem),
  header-md: clamp(1.2rem, 8.5vw, 2rem),
  header-sm: clamp(1.3rem, 6vw, 1.4rem),
  text: clamp(0.75rem, 3vw, 0.9rem),
);

// BreakPoints
$breakpoint: (
  small-xs: 450px,
  small: 640px,
  medium-sm: 700px,
  medium: 896px,
);

// Mixins
@mixin mq($key) {
  $size: map-get($breakpoint, $key);

  @media (max-width: $size) {
    @content;
  }
}

// ContainerMod

@mixin containMod {
  min-height: auto !important;
  margin-bottom: 0 !important;
  padding: 4em 4em 2em !important;
  @include mq(medium-sm) {
    padding: 2em !important;
  }
}
