@use "../abstracts" as *;
// <<<<<<<<<< FOOTER >>>>>>>>>>>>>>>

footer {
  width: 100%;
  min-height: 150px;
  background: linear-gradient(
    to right,
    map-get($colors, gradientdark1),
    map-get($colors, gradienttransdark)
  );
  margin-top: 2em;
  padding: 2em 4em;
  @include mq(medium-sm) {
    padding: 2em;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;

  .footer {
    &-section {
      display: flex;
      flex-direction: column;
      gap: 5px;

      &-logo {
        display: flex;
        gap: 4px;
        align-items: center;

        .logo_icon {
          img {
            width: 30px;
          }
        }

        p {
          font-size: clamp(0.6rem, 3vw, 0.7rem);
          font-weight: 300;
        }
      }

      .copy {
        font-size: 0.7rem;

        .year {
          font-weight: 200;
        }
      }
    }

    &-section1 {
      align-self: flex-end;
      overflow: hidden;

      p {
        font-size: 0.7rem;
        font-weight: 300;
      }
    }
  }
}
