@use "../abstracts" as *;

// <<<<<<<<<< HEADER >>>>>>>>>>>>>>>

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  backdrop-filter: blur(3px);
  padding: 2em 4em;
  @include mq(medium-sm) {
    padding: 2em;
  }
  position: fixed;
  top: 0;
  z-index: 10;

  &.hide .logo,
  &.hide .project_menu {
    opacity: 0;
    visibility: hidden;
  }

  // BUrger Menu
  .menu {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 10px;

    &_bars {
      width: $button-width;
      height: $button-width;
      position: relative;

      // Not Active Bars
      &.not--active::before {
        transform: translate(-50%, -4px) rotate(0) scaleX(1);
      }

      &.not--active::after {
        transform: translate(-50%, 2px) rotate(0) scaleX(1);
        animation: menuMove 5s ease 2s infinite;
      }

      @keyframes menuMove {
        30% {
          transform: translateX(-50%) translateY(2px) rotate(0) scaleX(0.4);
          transform-origin: left;
        }
        40% {
          transform-origin: right;
        }
        60%,
        80% {
          transform: translateX(-50%) translateY(2px) rotate(0) scaleX(1);
          transform-origin: right;
        }
      }

      // Active Bars
      &::before,
      &::after {
        position: absolute;
        content: "";
        width: $bar-width;
        height: $bar-height;
        background-color: map-get($colors, textcol);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        transition: 0.3s ease;
      }
      &::before {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    span {
      font-size: 0.9rem;
      font-weight: 400;
      @include mq(medium-sm) {
        display: none;
      }
    }
  }

  // Logo

  .logo {
    text-decoration: none;
    display: flex;
    gap: 8px;
    align-items: center;
    color: inherit;
    opacity: 1;

    transition: all 0.7s ease;

    p {
      font-size: 0.8rem;
      font-weight: 500;
      color: map-get($colors, textcol);
      line-height: 1;

      @include mq(medium-sm) {
        display: none;
      }

      span {
        font-size: 0.55rem;
        font-weight: 300;
      }
    }
  }

  // Project
  .project_menu {
    display: flex;
    align-items: center;
    gap: 10px;
    opacity: 1;
    transition: all 0.7s ease;

    span {
      font-size: 0.9rem;
      font-weight: 400;
      @include mq(medium-sm) {
        display: none;
      }
    }
  }
}

// <<<<<<<<<< NAV >>>>>>>>>>>>>>>

.nav {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  padding: 4em 0 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -997;
  opacity: 0;
  transform: scale(0);
  transform-origin: center;
  visibility: hidden;
  transition: all 0.4s ease;

  &.active {
    opacity: 1;
    transform: scale(1);
    z-index: 7;
    visibility: visible;
  }

  .main_nav {
    width: 60%;
    height: 80vh;
    padding: 2em 4em;
    @include mq(medium-sm) {
      width: 100%;
      padding: 2em;
    }

    &-bar {
      margin: 0;

      &-items {
        //li
        list-style: none;
        margin: 8px;

        &.active {
          a {
            color: rgb(234, 234, 234);
            position: relative;

            &::before,
            &::after {
              position: absolute;
              content: "";
              top: 50%;
              left: -40px;
              transform: translateY(-50%);
              width: 28px;
              aspect-ratio: 1;
              background-color: rgb(58, 108, 138);
              opacity: 0.2;
              z-index: -1;
              border-radius: 100vh;
            }

            &::after {
              width: 7px;
              opacity: 0.7;
              transform: translate(10.5px, -50%);
            }

            &::before {
              // transform: scale(1);
              animation: activeIcon 0.4s ease forwards;
            }

            @keyframes activeIcon {
              0% {
                transform: translateY(-50%) scale(0);
              }
              100% {
                transform: translateY(-50%) scale(1);
              }
            }
          }
        }

        a {
          font-size: clamp(1.6rem, 3vw, 3rem);
          font-weight: 300;
          color: rgb(132, 132, 132);
          transition: 0.4s ease;

          &:hover {
            color: rgb(72, 72, 72);
          }
        }
      }
    }
  }
}
