@use "./abstracts" as *;

//<<<<<<<<<<<<< LOGO >>>>>>>>>>>>>>>>>>>
.logo_icon {
  img {
    filter: brightness(0) invert(0.8);
  }
}

//<<<<<<<<<<<<< Page Headers >>>>>>>>>>>>>>>>>>>
.page-header {
  font-size: map-get($fz, header-md);
  font-weight: 300;
  margin-bottom: 0.7em;
  position: relative;
  display: inline-block;

  &::after {
    position: absolute;
    content: "";
    top: 50%;
    left: 110%;
    z-index: -1;
    transform: translateY(-50%);
    height: 1px;
    width: 70%;
    background: linear-gradient(
      to right,
      map-get($colors, gradientblue),
      map-get($colors, gradientdark)
    );
    border-radius: 100%;
  }
}

.page-header-md {
  font-size: map-get($fz, header-sm);
  font-weight: 300;
  margin-bottom: 0.7em;
}

//<<<<<<<<<<<<< BUTTONS >>>>>>>>>>>>>>>>>>>
.button {
  padding: 0.65em 1.8em;
  background-color: map-get($colors, dark);
  box-shadow: 3px -3px 15px 0px #1d2026;
  border-radius: 3px;
  font-size: clamp(0.75rem, 3.5vw, 0.9rem);
  transition: 0.3s ease;

  span {
    transition: 0.3s;
    padding-left: 0.2em;
    display: inline-block;
  }

  &:hover {
    background-color: #25475b;

    span {
      transform: translateX(0.25rem);
    }
  }
}

//<<<<<<<<<<<<< CONTAINER >>>>>>>>>>>>>>>>>>>
.contain {
  width: 100%;
  max-width: 1200px;
  min-height: 500px;
  margin: 0 auto 2em;
  padding: 4em;
  position: relative;
  overflow: hidden;

  @include mq(medium-sm) {
    padding: 2em;
  }
  @include mq(small) {
    min-height: 350px;
  }
}

//<<<<<<<<<<<<< CARDS >>>>>>>>>>>>>>>>>>>
.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, 0.345);
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  background-position: center;
  background-size: 100%;
  transition: all 1.2s ease;
  width: 60% !important;
  min-height: 350px;
  @include mq(medium) {
    min-height: 270px;
  }
  @include mq(medium-sm) {
    min-height: 196px;
    width: 80% !important;
  }
  border-radius: 8px;
  padding: 1.7em;
  @include mq(small) {
    padding: 1em;
  }
  margin-bottom: 30px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.708);
    background-size: 120%;
  }

  h3 {
    font-size: 1rem;
    font-weight: 400;
  }
}

.card-two {
  max-width: 500px;
  @include mq(medium) {
    max-width: 100%;
    margin-bottom: 3em;
  }
  height: fit-content;
  background: #242830;
  box-shadow: 3px -3px 15px 0px #1d2026;
  margin-bottom: 4em;
  padding: 1.5em;
  @include mq(small-xs) {
    padding: 1.2em;
  }
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .upper-sec {
    display: flex;
    align-items: center;
    gap: 20px;

    img {
      border-radius: 50%;
    }

    .position {
      h3 {
        font-weight: 500;
        font-size: 1rem;
      }

      p {
        font-size: clamp(0.72rem, 3vw, 0.8rem);
      }
    }
  }

  .bio {
    font-size: clamp(0.7rem, 3vw, 0.8rem);
  }
}

.card-three {
  width: 100%;
  min-height: 40px;
  padding: 0.8em;
  background: map-get($colors, dark);
  box-shadow: 3px -3px 15px 0px #1d2026;
  border-radius: 8px;
  display: flex;
  gap: 12px;
  align-items: flex-start;
  overflow: hidden;

  img {
    width: 35px;
  }

  ul {
    padding: 0;
    margin: 0;
    align-self: center;

    li {
      list-style: none;
      font-size: clamp(0.7rem, 3vw, 0.75rem);
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

//<<<<<<<<<<<<< SCROLL DOWN INDICATOR >>>>>>>>>>>>>>>>>>>

.box {
  position: absolute;
  bottom: 3em;
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
  visibility: visible;
  transition: 0.3s ease-in;

  &.hide {
    opacity: 0;
    visibility: hidden;
  }

  & span {
    width: 20px;
    height: 20px;
    display: block;
    border-right: 2px solid map-get($colors, textcol);
    border-bottom: 2px solid map-get($colors, textcol);
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 2s infinite;

    &:nth-child(2) {
      animation-delay: -0.2s;
    }
    &:nth-child(3) {
      animation-delay: -0.4s;
    }
  }
}

@keyframes animate {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: rotate(45deg) translate(10px, 10px);
  }
  100% {
    opacity: 0;
  }
}
