@use "../abstracts" as *;

.about {
  &-one {

    &::before {
      position: absolute;
      content: "";
      bottom: 0;
      right: 2em;
      width: 50%;
      height: 1px;
      background: linear-gradient(
        to right,
        map-get($colors, gradientdark),
        map-get($colors, gradientblue)
      );
      border-radius: 100%;
    }

    p {
      font-size: map-get($fz, text);
      font-weight: 300;
      line-height: 1.8;
      width: 70%;
      @include mq(small) {
        width: 100%;
      }
    }

    .boxes {
      display: inline-block;
      @include mq(small) {
        display: none;
      }
      position: absolute;
      top: 50%;
      transform: translateY(-50%) rotate(15deg);
      right: 6em;
      width: 200px;
      border-radius: 2px;
      opacity: 0;
      z-index: -1;
      aspect-ratio: 1;
      background-color: map-get($colors, dark);
      box-shadow: 3px -3px 15px 0px #1d2026;
      animation: grow 0.4s cubic-bezier(1, -0.57, 0.27, 1.27) 0.2s forwards;

      &::before {
        content: "";
        position: absolute;
        top: -40px;
        right: -40px;
        width: 70%;
        border-radius: 2px;

        opacity: 0;
        aspect-ratio: 1;
        background-color: #677b8093;
        animation: spin1 0.4s cubic-bezier(1, -0.57, 0.27, 1.27) 0.7s forwards;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: -30px;
        border-radius: 2px;

        left: -30px;
        width: 80%;
        opacity: 0;
        aspect-ratio: 1;
        background-color: #335e6aa0;
        animation: spin 0.4s cubic-bezier(1, -0.57, 0.27, 1.27) 0.7s forwards;
      }

      @keyframes spin {
        from {
          transform: rotate(0deg);
          opacity: 0;
        }
        to {
          transform: rotate(-30deg);
          opacity: 1;
        }
      }

      @keyframes spin1 {
        from {
          transform: rotate(-15deg);
          opacity: 0;
        }
        to {
          transform: rotate(15deg);
          opacity: 1;
        }
      }

      @keyframes grow {
        from {
          transform: translateY(-50%) rotate(15deg) scale(0);

          opacity: 0;
        }
        to {
          transform: translateY(-50%) rotate(15deg) scale(1);
          opacity: 1;
        }
      }
    }
  }

  &-two {
    display: flex;
    justify-content: flex-end;
    @include mq(medium) {
      justify-content: flex-start;
    }
    @include mq(small) {
      flex-direction: column;
      gap: 5em;
    }
    gap: 3em;
    // position: relative;

    &::before {
      position: absolute;
      content: "";
      top: 3em;
      left: 12%;
      height: 1px;
      width: 20%;
      z-index: -2;
      background: linear-gradient(
        to right,
        map-get($colors, gradientdark),
        map-get($colors, gradientblue),
        map-get($colors, gradientdark)
      );
      @include mq(small) {
        background: transparent;
      }
      border-radius: 100%;
    }

    &::after {
      position: absolute;
      content: "";
      top: 0;
      left: 17%;
      height: 100%;
      width: 1px;
      z-index: -2;
      background: linear-gradient(
        to bottom,
        map-get($colors, gradientdark),
        map-get($colors, gradientblue),
        map-get($colors, gradientdark)
      );
      @include mq(small) {
        background: transparent;
      }
      border-radius: 100%;
    }

    .left-section {
      max-width: 350px;
      @include mq(medium-sm) {
        max-width: 100%;
      }
      @include mq(small) {
        border: 2px solid map-get($colors, dark);
        border-left-color: transparent;
        padding-left: 0;
      }
      padding: 1.5em;
      border-radius: 8px;

      p {
        font-size: clamp(0.8rem, 3vw, 0.85rem);
      }
    }

    .right-section-wrapper {
      @include mq(small) {
        align-self: center;
      }

      .right-section {
        max-width: 350px;
        width: 100%;
        background: linear-gradient(120deg, rgb(50, 94, 121), rgb(30, 55, 70));
        padding: 1.5em;
        border-radius: 8px;
        position: relative;

        &::before {
          position: absolute;
          content: "";
          inset: 0;
          background: map-get($colors, gradientblue);
          border-radius: 8px;
          transform: rotate(5deg);
          z-index: -1;
        }

        p {
          font-size: clamp(0.8rem, 3vw, 0.85rem);
        }
      }
    }
  }

  &-three {
    display: flex;
    flex-direction: column;
    gap: 3em;

    .flex {
      display: flex;
      justify-content: space-between;
      gap: 4em;
      @include mq(medium) {
        gap: 0;
        flex-wrap: wrap;
      }

      &-area {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .last-card {
          position: relative;
          margin-bottom: 0 !important;
          &::before {
            position: absolute;
            content: "";
            bottom: 105%;
            left: 50%;
            transform: translateX(-50%);
            height: 60%;
            width: 1px;
            background: linear-gradient(
              to bottom,
              map-get($colors, gradientdark),
              map-get($colors, gradientblue)
            );
            border-radius: 100%;
            @include mq(medium) {
              background: transparent;
            }
          }

          &::after {
            position: absolute;
            content: "";
            top: 105%;
            left: 50%;
            transform: translateX(-50%);
            height: 60%;
            width: 1px;
            background: linear-gradient(
              to bottom,
              map-get($colors, gradientblue),
              map-get($colors, gradientdark)
            );
            border-radius: 100%;
            @include mq(medium) {
              background: transparent;
            }
          }
        }
      }
    }
  }
}
