@use "../abstracts" as *;

// <<<<<<<<<< HERO SECTION >>>>>>>>>>>>>>>
.hero {
  max-width: 1300px;
  min-height: 500px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  // align-items: center;

  &-text {
    max-width: 800px;
    min-height: inherit;
    @include mq(small) {
      min-height: 400px;
    }
    padding: 3em 4em;
    @include mq(medium-sm) {
      padding: 1.5em 2em;
    }
    position: relative;

    &::before {
      content: "";
      position: absolute;
      bottom: 90px;
      right: 70px;
      height: 1px;
      width: 50%;
      background: linear-gradient(
        to right,
        map-get($colors, gradientdark),
        map-get($colors, gradientblue)
      );
      @include mq(medium-sm) {
        background: transparent;
      }
      border-radius: 100%;
    }

    h1 {
      font-size: map-get($fz, header);
      font-weight: 400;
      letter-spacing: 0.1px;
      line-height: 1.2;
      margin: 0.5em 0 0.3em;
    }

    p {
      max-width: 400px;
      margin: 1em 0 2.5em;
    }
  }

  &-image {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    svg {
      width: 656px;
      height: 517px;

      #backmain-one,
      #backmain-two,
      #backmain-three,
      #topmain-one,
      #topmain-two,
      #outmain-small,
      #outmain-blue,
      #outmain-dark {
        transform-origin: center;
        transform-box: fill-box;
      }
      #backmain-one {
        animation: rotate 0.4s cubic-bezier(1, -0.57, 0.27, 1.27) 0.7s forwards;
        opacity: 0;
      }
      #backmain-two {
        animation: rotate2 0.4s cubic-bezier(1, -0.57, 0.27, 1.27) 0.7s forwards;
        opacity: 0;
      }
      #backmain-three {
        animation: rotate3 0.4s cubic-bezier(1, -0.57, 0.27, 1.27) 0.7s forwards;
        opacity: 0;
      }
      #topmain-one,
      #topmain-two {
        animation: rotate3 0.4s cubic-bezier(1, -0.57, 0.27, 1.87) 0.4s forwards;
        opacity: 0;
      }
      #main {
        animation: scaling 0.4s ease-in forwards;
        transform-origin: center;
        opacity: 0;
      }

      #outmain-small,
      #outmain-blue,
      #outmain-dark {
        animation: rotate4 0.4s cubic-bezier(1, -0.57, 0.27, 1.87) 0.7s forwards;
        opacity: 0;
        transform: rotateZ(27deg);
      }

      @keyframes rotate {
        from {
          transform: translate(-111px, 55px) rotateZ(-20deg);
          opacity: 0;
        }
        to {
          transform: translate(-111px, 55px) rotateZ(37deg);
          opacity: 1;
        }
      }
      @keyframes rotate2 {
        from {
          transform: translate(-80px, 40px) rotateZ(-20deg);
          opacity: 0;
        }
        to {
          transform: translate(-80px, 40px) rotateZ(37deg);
          opacity: 1;
        }
      }
      @keyframes rotate3 {
        from {
          transform: translate(-2px, 1px) rotateZ(37deg);
          opacity: 0;
        }
        to {
          transform: translate(-2px, 1px) rotateZ(0deg);
          opacity: 1;
        }
      }

      @keyframes rotate4 {
        from {
          transform: translate(-2px, 1px) rotateZ(0deg);
          opacity: 0;
        }
        to {
          transform: translate(-2px, 1px) rotateZ(30deg);
          opacity: 1;
        }
      }
      @keyframes scaling {
        0%,
        40% {
          transform: scaleY(0) scaleX(0.3);
          opacity: 0;
        }
        50% {
          transform: scaleY(0) scaleX(1);
          opacity: 0.7;
        }
        100% {
          transform: scaleY(1);
          opacity: 1;
        }
      }
    }
  }
}

// <<<<<<<<<< HOME SECTION >>>>>>>>>>>>>>>
.home {
  // <<<<<<<<<< HOME ABOUT SECTION >>>>>>>>>>>>>>>
  &-about {
    display: flex;
    gap: 30px;

    .images {
      display: flex;
      @include mq(small) {
        display: none;
      }
      // justify-content: center;
      gap: 30px;
      width: 50%;
      @include mq(medium-sm) {
        width: fit-content;
      }

      .img {
        width: 200px;
        @include mq(medium) {
          width: 300px;
        }
        object-fit: cover;
        border-radius: 5px;

        &.one {
          height: 350px;
          @include mq(medium) {
            display: none;
          }
        }
        &.two {
          height: 150px;
        }
        &.three {
          height: 300px;
        }
      }

      .inner-images {
        display: flex;
        flex-direction: column;
        gap: 30px;
      }
    }

    .description {
      width: 50%;
      @include mq(medium-sm) {
        width: 100%;
      }
      display: flex;
      flex-direction: column;
      gap: 1.5em;
      padding: 0.7em 0;
      position: relative;

      &::before,
      &::after {
        position: absolute;
        content: "";
        left: 0;
      }

      &::before {
        top: 0;
        height: 1px;
        width: 50%;
        background: linear-gradient(
          to right,
          map-get($colors, gradientblue),
          map-get($colors, gradientdark)
        );
        border-radius: 100%;
      }

      &::after {
        top: 15em;
        width: 1px;
        height: 40%;
        @include mq(small) {
          height: 25%;
        }
        background: linear-gradient(
          to bottom,
          map-get($colors, gradientblue),
          map-get($colors, gradientdark)
        );
        border-radius: 100%;
      }

      h2 {
        font-weight: 400;
        margin-top: 1em;
      }

      a {
        align-self: flex-end;
      }
    }
  }

  // <<<<<<<<<< HOME PROJECT SECTION >>>>>>>>>>>>>>>
  &-project {
    @include containMod;

    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 50%;
      height: 1px;
      background: linear-gradient(
        to right,
        map-get($colors, gradientdark),
        map-get($colors, gradientblue),
        map-get($colors, gradientdark)
      );
      border-radius: 100%;
    }

    h2 {
      font-weight: 400;
    }

    &-slider {
      position: relative;

      &::before {
        position: absolute;
        content: "";
        top: 80%;
        left: 50%;
        transform: translateX(-50%);
        width: 1px;
        height: 40%;
        background: linear-gradient(
          to bottom,
          map-get($colors, gradientblue),
          map-get($colors, gradientdark)
        );
        border-radius: 100%;
        z-index: -1;
      }

      .project-link {
        padding: 2em;
        display: flex;

        a {
          display: inline-block;
          margin-left: auto;
        }
      }

      .swiper-contain {
        .swiper-wrapper {
          align-items: center;
        }

        .swiper-slide {
          transition-property: all;
        }

        .swiper-pagination {
          bottom: 0;
          text-align: right;
          padding-right: 2em;

          &-bullet {
            width: 24px;
            height: 2.5px;
            border-radius: 10px;
            margin: 0 3px;

            &-active {
              background: map-get($colors, gradientblue);
            }
          }
        }
      }
    }
  }
}
